// local: http://localhost:3002/v1
// remote: http://www.ryanlawton.art:3332/v1
const END_POINT = "http://www.ryanlawton.art:3332/v1";

export const DECK = {
  getAll: async () => {
    let response;
    await fetch(`${END_POINT}/deck`)
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  },
  get: async (id) => {
    let response;
    await fetch(`${END_POINT}/deck/${id}`)
    .then(res => res.json())
    .then((data) => {
      response = data;
      console.log(data)
    })
    .catch(console.log)
    return response;
  },
  create: async(deck) => {
    let response;
    await fetch(`${END_POINT}/deck`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(deck)
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  },
  edit: async(deck) => {
    let response;
    await fetch(`${END_POINT}/deck/${deck.deckId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(deck)
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  },
  delete: async(id) => {
    let response;
    await fetch(`${END_POINT}/deck/${id}`, {
      method: 'DELETE'
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  }
}

export const CARD = {
  add: async(card) => {
    let response;
    await fetch(`${END_POINT}/card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(card)
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  },
  edit: async(card) => {
    let response;
    await fetch(`${END_POINT}/card/${card.cardId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(card)
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  },
  editAll: async(cards) => {
    let response;
    await fetch(`${END_POINT}/card/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cards)
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  },
  delete: async(
    cardId
  ) => {
    let response;
    await fetch(`${END_POINT}/card/${cardId}`, {
      method: 'DELETE'
    })
    .then(res => res.json())
    .then((data) => {
      response = data;
    })
    .catch(console.log)
    return response;
  }
}