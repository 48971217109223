import seedrandom from 'seedrandom';
import sha1 from 'sha1';

export function generateSeed(seed){
  return sha1(seed)
}

export function randomNumberGenerator(hash){
  return seedrandom(hash)
}

export const urlEncoder = (data) => {
  let formBody = [];
  for (let property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}