import React from "react";
import { Tile, Tag } from "./Tile";
import './index.css';
import { randomNumberGenerator } from "../../logic/utils";
import { Victory } from "./Victory";

export class Bingo extends React.Component {
  constructor(props) {
    super(props);

    // Need to handle prop size configuration once different data sets can be used
    // Otherwise app will crash
    const size = props.size < 1 ? 1 : (props.size > 8 ? 8 : props.size);

    let board = this.generateBoard(size, props.data, props.seed);
    
    this.state = {
      data: props.data,
      size,
      board,
      seed: props.seed,
      won: false,
      theme: props.theme,
      animation: props.animation,
    }

    this.handleLeftClick = this.handleLeftClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const size = nextProps.size < 1 ? 1 : (nextProps.size > 8 ? 8 : nextProps.size);
    const board = this.generateBoard(size, nextProps.data, nextProps.seed);

    this.setState({ 
      size,
      board,
      seed: nextProps.seed,
      theme: nextProps.theme,
      animation: nextProps.animation,
      selected: nextProps.selected,
    });
  }

  componentDidMount(){
    this.checkWinCondition();
  }

  /**
   * @param {index of board tile} i
   * @description updates flag for board tile 
   */
  handleLeftClick(i, tag){
    let board = this.state.board;
    if (board[i].selected !== tag) board[i].selected = tag
    else board[i].selected = Tag.UNSELECTED

    this.setState({
      board
    });
    this.setState({
      won: this.checkWinCondition()
    });
  }

  /**
   * @description Checks if player has won
   */
  checkWinCondition(){
    let i, j;

    try{
      // Vertical Slices
      for (i = 0; i < this.state.size; i++){
        let win = true;
        for (j = 0; j < this.state.size; j++){
          if (this.state.board[i + (j * this.state.size)].selected !== Tag.SELECTED){
            win = false;
            break;
          }
        }
        if (!!win){
          return true;
        }
      }

      // Horizontal Slices
      for (i = 0; i < this.state.size; i++){
        let win = true;
        for (j = 0; j < this.state.size; j++){
          if (this.state.board[j + (i * this.state.size)].selected !== Tag.SELECTED){
            win = false;
            break;
          }
        }
        if (!!win){
          return true;
        }
      }

      // Check Diagonals
      let win = true;
      for (i = 0; i < this.state.size; i++){
        if (this.state.board[i + (i * this.state.size)].selected !== Tag.SELECTED){
          win = false;
          break;
        }
      }
      if (!!win){
        return true;
      }
      win = true;
      for (i = 0; i < this.state.size; i++){
        if (this.state.board[(this.state.size - 1 - i) + (i * this.state.size)].selected !== Tag.SELECTED){
          win = false;
          break;
        }
      }
      if (!!win){
        return true;
      }
    } catch (err) {
      return false;
    }

    return false;
  }

  generateBoard(size, data, seed){
    let board = new Array(size * size).fill(0);
    let usedValues = [];

    if(size * size > data.length){
      return [];
    }

    let random = randomNumberGenerator(seed);

    // Need to figure out how to consider weighted tiles
    board = board.map(_ => {
      let randomNumber = Math.floor(random() * (data.length));
      
      while (usedValues.includes(randomNumber)){
        randomNumber = Math.floor(random() * (data.length));
      }
      usedValues.push(randomNumber);
      return {
        selected: Tag.UNSELECTED, 
        ...data[randomNumber]
      }
    });

    return board;
  }

  render() {
    const num_tiles = this.state.board.map((e, i, a) => 
      <Tile 
        idx={i}
        handleLeftClick={ this.handleLeftClick }
        handleRightClick={ this.handleRightClick }
        size= { this.state.size }
        title= {e.title}
        value= {e.value}
        description= {e.description}
        selected= {e.selected}
        theme= {this.state.theme}
        animation = {this.state.animation}
      />
    );
    return (
      <div className="bingo" data-theme={this.state.theme}>
        { this.state.won && <Victory />}
        <div className="bingo-board" style={{width: 160 * this.state.size, height: 160 * this.state.size}}>
          {num_tiles}
        </div>
      </div>
    );
  }
}