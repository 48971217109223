import './App.css';
import React from 'react';
import { Bingo } from './components/Bingo';
import { Toolbar } from './components/Toolbar';
import { DeckEditor } from './components/DeckEditor';
import { generateSeed } from './logic/utils';
import { DECK } from './config/endpoints';

export class App extends React.Component {
  constructor(props) {
    super(props);

    const existingTheme = localStorage.getItem('theme');
    const theme = existingTheme==='light' ? 'light' : 'dark';
    localStorage.setItem('theme', theme);

    this.state = {
      size: 5,
      data: [],
      seed: generateSeed(new Date().getTime()),
      theme,
      animation: "on",
      appFocus: true,
      selectedCards: [],
    }

    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleSeedChange = this.handleSeedChange.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleAnimationChange = this.handleAnimationChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.handleDeckChange = this.handleDeckChange.bind(this);
  }

  async componentDidMount() {
    let decks = await DECK.getAll();
    this.setState({
      data: decks,
      selectedCards: decks[0].cards,
      selectedDeck: decks[0],
    })
  }

  handleSizeChange(event){
    this.setState({
      size: event.target.value
    })
  }

  handleSeedChange(event){
    this.setState({
      seed: event.target.value
    })
  }

  handleThemeChange(_){
    const theme = localStorage.getItem('theme') === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', theme);
    this.setState({
      theme
    })
  }

  handleAnimationChange(_){
    const animation = this.state.animation === 'on' ? 'off' : 'on';
    this.setState({
      animation
    })
  }

  handleFocusChange(_){
    const appFocus = this.state.appFocus === true ? false : true;
    this.setState({
      appFocus
    })
  }

  handleDeckChange(e){
    const deckTitle = e.target.value;
    const deck = this.state.data.filter((deck) => {
      return deck.title === deckTitle;
    });
    this.setState({
      selectedCards: deck[0].cards,
    })
  }

  render() {
    return (
      <div className="app" data-theme={this.state.theme}>
        <div className='app-background'/>
        
        <Toolbar
              handleSeedChange={this.handleSeedChange}
              handleSizeChange={this.handleSizeChange}
              handleThemeChange={this.handleThemeChange}
              handleAnimationChange={this.handleAnimationChange}
              handleDeckChange={this.handleDeckChange}
              handleFocusChange={this.handleFocusChange}
              data={this.state.data}
              size={this.state.size} 
              seed={this.state.seed}
              theme={this.state.theme}
              animation={this.state.animation}
              appFocus={this.state.appFocus}
            />
        { this.state.appFocus && 
            <Bingo 
              size={this.state.size} 
              data={this.state.selectedCards} 
              seed={this.state.seed}
              theme={this.state.theme}
              animation={this.state.animation}
            />
        }
        { !this.state.appFocus &&
          <div>
            <DeckEditor
              data={this.state.data}
              theme={this.state.theme}
              animation={this.state.animation}
            />
          </div>
        }
    </div>
    );
  }
}