import React from "react";
import './index.css';

export const TileType = {
  CARD: 0,
  OPTIONS: 1,
}

export const Tag = {
  UNSELECTED: 0,
  SELECTED: 1,
  TAG_A: 2,
  TAG_B: 3,
  TAG_C: 4,
  TAG_D: 5,
}

export class Tile extends React.Component{
  constructor(props){
    super(props);

    this.handleLeftClick = this.props.handleLeftClick.bind(this);

    this.state = {
      idx: props.idx,
      title: props.title,
      value: props.value,
      description: props.description,
      selected: props.selected,
      tileType: TileType.CARD,
      theme: props.theme,
      animation: props.animation
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ 
      idx: nextProps.idx,
      title: nextProps.title,
      value: nextProps.value,
      description: nextProps.description,
      selected: nextProps.selected,
      theme: nextProps.theme,
      animation: nextProps.animation
    });
  }

  onMouseEnter() {
    document.addEventListener("click", this.handleClick);
    document.addEventListener("contextmenu", this.handleContextMenu);
  }

  onMouseLeave() {
    document.removeEventListener("click", this.handleClick);
    document.removeEventListener("contextmenu", this.handleContextMenu);
    this.setState({ tileType: TileType.CARD });
  }

  handleClick = (e) => {
    let tag = this.state.tag;

    switch(e.target.className){
      case "tile-options-a":
        tag = Tag.TAG_A;
        break;
      case "tile-options-b":
        tag = Tag.TAG_B;
        break;
      case "tile-options-c":
        tag = Tag.TAG_C;
        break;
      case "tile-options-d":
        tag = Tag.TAG_D;
        break;
      default:
        tag = Tag.SELECTED
        break;
    };

    this.setState({
      tileType: TileType.CARD,
    })

    this.handleLeftClick(this.state.idx, tag);
  }

  handleContextMenu = (e) => {
    e.preventDefault();
    this.setState({
      tileType: TileType.OPTIONS
    });
  }

  render(){
    let status = '';

    switch(this.state.selected){
      case Tag.SELECTED:
        status = 'tile-selected';
        break;
      case Tag.TAG_A:
        status = 'tile-tag-a';
        break;
      case Tag.TAG_B:
        status = 'tile-tag-b';
        break;
      case Tag.TAG_C:
        status = 'tile-tag-c';
        break;
      case Tag.TAG_D:
        status = 'tile-tag-d';
        break;
      default:
        break;
    }

    return(
      <div className={"tile " + status}
        data-theme={this.state.theme}
        animation={this.state.animation}
        onMouseEnter={e => {this.onMouseEnter()}}
        onMouseLeave={e => {this.onMouseLeave()}}
      >
        { 
          this.state.tileType === TileType.CARD && <div>
            <div className="tile-back">{this.state.description}<div className="tile-overlay"/></div>
            <div className="tile-front">{this.state.title}<div className="tile-overlay"/></div>
          </div>
        }
        { 
          this.state.tileType === TileType.OPTIONS && 
          <div>
            <div className="tile-options-a"/>
            <div className="tile-options-b"/>
            <div className="tile-options-c"/>
            <div className="tile-options-d"/>
          </div>
        }
        <span className="black">&nbsp;</span>
      </div>
    );
  }
}