import React from "react";
import './index.css';

export class Victory extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      theme: props.theme
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ 
      theme: nextProps.theme,
    });
  }

  render(){
    return(
      <div className="victory" data-theme={this.state.theme}>
        BINGO
      </div>
    );
  }
}