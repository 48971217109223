import react from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import "./index.css";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import ContentEditable from 'react-contenteditable'
import { CARD, DECK } from "../../config/endpoints";

export class DeckEditor extends react.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      theme: props.theme,
      animation: props.animation,
      deck: props.data[0],
      cards: props.data[0].cards,
      cursorIndex: 0,
    }

    this.handleDeckTitleChange = this.handleDeckTitleChange.bind(this);
    this.handleDeckGameChange = this.handleDeckGameChange.bind(this);
    this.handleAddNewDeck = this.handleAddNewDeck.bind(this);
    this.handleAddNewCard = this.handleAddNewCard.bind(this);
    this.handleDelNewCard = this.handleDelNewCard.bind(this);
    this.sendDeckUpdate = this.sendDeckUpdate.bind(this);
    this.sendAllCardsUpdate = this.sendAllCardsUpdate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ 
      data: nextProps.data,
      theme: nextProps.theme,
      animation: nextProps.animation,
    });
  }


  handleSetCardTitle = (e, index) => {
    let cards = this.state.cards;
    let deck = this.state.deck;
    cards[index].title = e.target.value;
    deck.cards = cards;
    this.setState({
      cards,
      deck
    })
    return this.handleCardChange;
  }

  handleSetCardDescription = (e, index) => {
    let cards = this.state.cards;
    let deck = this.state.deck;
    cards[index].description = e.target.value;
    deck.cards = cards;
    this.setState({
      cards,
      deck
    })
    return this.handleCardChange;
  }

  generateCards(){
    const cards = this.state.cards.map((card, index) => {
      let contentEditableTitle = react.createRef();
      let contentEditableDescription = react.createRef();
      return (
        <Card className="deck-builder-card">
          <Card.Title type="text"
            className="deck-builder-card-title"
            >
              <ContentEditable
                className="deck-builder-card-title-box"
                innerRef={contentEditableTitle}
                disabled={false}
                onBlur={(e) => this.sendCardUpdate(e, index)}
                onChange={(e) => this.handleSetCardTitle(e, index)}
                html={card.title}
              />
            </Card.Title>
          <Card.Text type="text" className="deck-builder-card-text">
            <ContentEditable
                className="deck-builder-card-text-box"
                innerRef={contentEditableDescription}
                disabled={false}
                onChange={(e) => this.handleSetCardDescription(e, index)}
                html={card.description}
              />
          </Card.Text>
          <Button className="deck-builder-card-delete" variant="primary" value={card.title} onClick={this.handleDelNewCard}>Delete</Button>
        </Card>
      )
    })

    cards.push(
      <Card className="deck-builder-card-final">
        <Button className="deck-builder-card-add-button" onClick={this.handleAddNewCard}>Add Card</Button>
      </Card>
    )

    return cards;
  }

  getDeckOptions(){
    const decks = this.state.data.map((e) => {
      return <option>
        {e.title}
      </option>
    })

    return decks;
  }

  handleDeckChange(e){
    const deckTitle = e.target.value;
    const deck = this.state.data.filter((deck) => {
      return deck.title === deckTitle;
    });
    this.setState({
      deck: deck[0],
      cards: deck[0].cards,
    })
  }

  async handleAddNewDeck(_){
    const deck = await DECK.create({
      deck: {
        title: "",
        game: ""
      }
    });
    deck.cards = [];
    const data = this.state.data;
    data.push(deck);
    this.setState({
      data,
      deck,
      cards: deck.cards,
    })
  }

  handleDeckTitleChange(event){
    const deck = this.state.deck;
    deck.title = event.target.value;
    this.setState({
      deck
    })
  }

  handleDeckGameChange(event){
    const deck = this.state.deck;
    deck.game = event.target.value;
    this.setState({
      deck
    })
  } 

  async handleAddNewCard(_){
    const card = await CARD.add({
      card: {
        title: "",
        value: 1,
        description: "",
        deckId: this.state.deck.deckId
      }
    });
    const cards = this.state.cards;
    cards.push(card);
    this.setState({
      cards
    })
  }

  async handleDelNewCard(event){
    const cards = this.state.cards;
    const index = cards.findIndex((card) => card.title === event.target.value);
    await CARD.delete(cards[index].cardId);
    cards.splice(index, 1);
    this.setState({
      cards
    });
  }

  async sendDeckUpdate() {
    await DECK.edit({
      deck: this.state.deck
    });
  }

  async sendAllCardsUpdate() {
    const cards = this.state.deck.cards;
    await CARD.editAll({
      cards
    });
  }

  async sendCardUpdate(e, index) {
    const cards = this.state.cards;
    const card = cards[index];
    card.deckId = this.state.deck.deckId;
    await CARD.edit(card);
  }

  render(){
    return (
      <div className="deck-builder">
        <Form className="deck-builder-options">
          <Row className="mb-3">
            <Col>
              <InputGroup>
                <InputGroup.Text>Select Deck</InputGroup.Text>
                <Form.Select className="deck-builder-input" value={this.state.deck.title} onChange={this.handleDeckChange.bind(this)}>
                  {this.getDeckOptions()}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col>
              <Button onClick={this.handleAddNewDeck}>Add New Deck</Button>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col>
              <InputGroup>
                <InputGroup.Text>Title</InputGroup.Text>
                <FormControl 
                  placeholder="Deck Title" 
                  onBlur={this.sendDeckUpdate} 
                  value={this.state.deck.title} 
                  onChange={this.handleDeckTitleChange}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroup.Text>Game</InputGroup.Text>
                <FormControl 
                  placeholder="Game Associated with Deck" 
                  onBlur={this.sendDeckUpdate} 
                  value={this.state.deck.game} 
                  onChange={this.handleDeckGameChange}
                />
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <div className="deck-builder-cards">
          {this.generateCards()}
        </div>
      </div>
    )
  }
}