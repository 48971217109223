import react from "react";
import { generateSeed } from "../../logic/utils";
import { Button, Col, Form, Row } from 'react-bootstrap';
import "./index.css";

export class Toolbar extends react.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      size: props.size,
      seed: props.seed,
      theme: props.theme,
      animation: props.animation,
      appFocus: props.appFocus,
    }

    this.handleSizeChange = this.props.handleSizeChange.bind(this);
    this.handleSeedChange = this.props.handleSeedChange.bind(this);
    this.handleThemeChange = this.props.handleThemeChange.bind(this);
    this.handleAnimationChange = this.props.handleAnimationChange.bind(this);
    this.handleDeckChange = this.props.handleDeckChange;
    this.handleFocusChange = this.props.handleFocusChange.bind(this);
  }


  componentWillReceiveProps(nextProps) {
    this.setState({ 
      data: nextProps.data,
      size: nextProps.size,
      seed: nextProps.seed,
      theme: nextProps.theme,
      animation: nextProps.animation,
      appFocus: nextProps.appFocus,
    });
  }

  render(){
    const decks = this.state.data.map((e) => {
      return <option>
        {e.title}
      </option>
    });
    return (
      <div className="toolbar">
        <Form>
          <Row>
            <Col>
              <Button className="toolbar-button" onClick={this.handleFocusChange}>
                {this.state.appFocus ? "Deck Editor" : "App"}
              </Button>
            </Col>
            <Col>
              <Form.Label className="toolbar-label">Seed: </Form.Label>
              <Form.Control className="toolbar-input" value={this.state.size} onChange={this.handleSizeChange}/>
            </Col>
            <Col>
              <Form.Label className="toolbar-label">Seed: </Form.Label>
              <Form.Control className="toolbar-input" value={this.state.seed} onChange={this.handleSeedChange}/>
            </Col>
            <Col>
              <Form.Group onChange={this.handleDeckChange.bind(this)}>
                <Form.Label className="toolbar-label">Select Deck: </Form.Label>
                <Form.Select className="toolbar-input" label>
                  {decks}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Button className="toolbar-button" onClick={() => this.handleSeedChange({
                target : {value : generateSeed(new Date().getTime())}
              })}>
                Reset
              </Button>
            </Col>
            <Col>
              <Button className="toolbar-button" onClick={() => this.handleThemeChange()}>
                Switch to {this.state.theme === 'light' ? 'Dark' : 'Light'} Theme
              </Button>
            </Col>
            <Col>
              <Button className="toolbar-button" onClick={() => this.handleAnimationChange()}>
                Turn {this.state.animation === 'on' ? 'off' : 'on'} Animation
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}